<template>
    <router-view></router-view>
 </template>
 <script>
 export default {
   beforeMount: function () {
     this.$router.replace(window.location.pathname)
   }
 }
 </script>
 <style>
 @font-face {
     font-family: Mokoto;
     src: url('../fonts/Mokoto\ Glitch.ttf');
 }

</style>