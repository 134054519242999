import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/dashboard.vue')
  },
  {
    path: '/pool/:address',
    name: 'Pool',
    component: () => import('../components/pool.vue')
  },
  {
    path: '/status',
    name: 'Status',
    component: () => import('../components/status.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../components/signup.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../components/dashboard.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../components/error.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
