import { io } from 'socket.io-client'
import { createApp } from 'vue'
import root from '../components/root.vue'
import router from '../router'




const app = createApp(root)
app.config.globalProperties.$soket = io()
app.use(router)
app.mount('#app')